<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Dashboard component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Nazox",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      cpuused: 50,
      cpu_progresscolor: "#20a53a",
      memory: 0,
      memory_progresscolor: "#20a53a",
      hostname: "",
      statistics: [],
      salesdata: [],
      options: {
        chart: {
          id: "dashchart",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [],
      userinfo: [],
      iplist: {
        ipv4: [],
        ipv6: "",
      },
      noticelist: [],
      timer: null,
      todolist: [],
      stocklist: [],
      dialogVisible: false,
      form: {
        oldPassword: 'admin',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        newPassword: [
          { required: true, message: this.$t('home.password.newpassword_tip'), trigger: 'blur' },
          { min: 6, message: this.$t('home.password.newpassword_length'), trigger: 'blur' },
          { validator: this.validateNewPassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: this.$t('home.password.newpassword_check_tip'), trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' }
        ]
      },
      otp_status: false,
      stock_status: []
    };
  },
  mounted() {
    var that = this;
    that.getotpstatus();
    that.title = that.$t("dashboard.text");
    that.items = [
      {
        text: that.$t("dashboard.text"),
        href: "/",
      },
    ];
    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("notice")) {
      that.noticelist = JSON.parse(localStorage.getItem("notice"));
    }
    that.timer = setInterval(() => {
      that.getsalesdata();
      that.getStockStatus();
    }, 5000);
    that.updateprintersetting();
    that.gettodolist();
    that.getsalesdata();
    that.getStockStatus();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    handdleMsg(msg) { },
    getsalesdata() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getsalesdata",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.salesdata = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    gettodolist() {
      var that = this;
      that.todolist = [];
      that.$axios
        .post(
          that.apiuri,
          {
            action: "gettodolist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.todolist = response.data.data;
          // 查找todolist中有没有type=account，msg包含password的数据
          var account = that.todolist.find((item) => {
            return item.type === "account" && item.msg.includes("password");
          });
          if (account) {
            that.dialogVisible = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      // console.log(that.$t("menuitems.clouds.printer.text"))
    },
    getStockStatus() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "get_hd_stock_status",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.stocklist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    updateprintersetting() {
      var that = this
      that.$axios
        .post(
          that.apiuri,
          {
            action: "reloadprinter"
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          // that.getprintersetting();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 执行密码修改逻辑
          if (this.otp_status) {
            this.$prompt(
              this.$t("setting.system.otpbind_input"),
              this.$t("setting.system.otpbind_confirm"),
              {
                confirmButtonText: this.$t("global.button.ok"),
                cancelButtonText: this.$t("global.button.cancel"),
                inputPattern: /^[0-9]{6}$/,
                inputErrorMessage: this.$t("setting.system.otpbind_input_error"),
              }
            ).then(({ value }) => {
              this.$axios.post(this.apiuri, {
                action: "checkotptoken_online",
                code: value
              },
                {
                  headers: { Openid: this.userinfo.openid },
                }
              ).then((response) => {
                if (response.data.status === 200) {
                  this.sendPasswordChange();
                } else {
                  this.$message({
                    message: this.$t("setting.system.otpcheck_failed"),
                    type: "error",
                  });
                }
              }).catch((error) => {
                this.$message({
                  message: this.$t("setting.system.otpbind_error"),
                  type: "error",
                });
              });
            }).catch(() => { });
          } else {
            this.sendPasswordChange();
          }

        } else {
          this.$message.error($t('global.form.validate_failed'));
          return false;
        }
      });
    },
    validateNewPassword(rule, value, callback) {
      const lengthValid = value.length > 6;
      const hasLetter = /[a-zA-Z]/.test(value);
      const hasDigit = /\d/.test(value);
      const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);

      const typesCount = [hasLetter, hasDigit, hasSymbol, hasUpperCase].filter(Boolean).length;

      if (!lengthValid) {
        callback(new Error(this.$t('home.password.newpassword_length')));
      } else if (typesCount < 3) {
        callback(new Error(this.$t('home.password.newpassword_type')));
      } else {
        callback();
      }
    },
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.form.newPassword) {
        callback(new Error(this.$t('home.password.newpassword_double')));
      } else {
        callback();
      }
    },
    sendPasswordChange() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "changeadminpwd",
            password: that.form.confirmPassword,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.gettodolist();
          if (response.data.status == 200) {
            that.dialogVisible = false;
            that.$message.success(that.$t('home.password.success'));
          } else {
            that.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getotpstatus() {
      this.$axios
        .post(this.apiuri, { action: 'getotpstatus' })
        .then((response) => {
          if (response.data.status === 200) {
            this.otp_status = true;
          }
        })
        .catch((error) => {
          console.error('获取 OTP 状态失败:', error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-6 col-lg-6 col-sm-6">
        <div class="card card-block card-stretch card-height">
          <div class="card-body topmodel">
            <h5>{{ $t("dashboard.waitjob") }}</h5>
            <div class="showbox">
              <el-empty :description="$t('dashboard.nostatistics')" v-if="todolist.length === 0"></el-empty>
              <el-table v-else :data="todolist" style="width: 100%" :show-header="false">
                <el-table-column :label="$t('dashboard.waitjob')">
                  <template slot-scope="scope">
                    <span v-if="scope.row.type == 'account'">
                      {{
                        $t("dashboard.todolist.account", {
                          username: scope.row.username,
                        })
                      }}
                      <span v-for="(val, idx) in scope.row.msg" :key="idx">
                        {{ $t("dashboard.todolist." + val) }}
                      </span>
                    </span>
                    <span v-else-if="scope.row.type == 'device'">
                      {{
                        $t("dashboard.todolist.devices", {
                          device: scope.row.ED_id,
                        })
                      }}
                      <span v-for="(val, idx) in scope.row.msg" :key="idx">
                        {{ $t("dashboard.todolist." + val) }}
                      </span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-6">
        <div class="card card-block card-stretch card-height">
          <div class="card-body topmodel">
            <h5>{{ $t("dashboard.stockjob") }}</h5>
            <div class="showbox">
              <el-empty :description="$t('dashboard.nostatistics')" v-if="stocklist.length === 0"></el-empty>
              <el-table v-else :data="stocklist" style="width: 100%" :show-header="false">
                <el-table-column :label="$t('dashboard.waitjob')">
                  <template slot-scope="scope">
                    <span>
                      {{ $t("dashboard.todolist.stock", {
                        title: scope.row.title,
                        free: scope.row.remaining_percentage,
                      }) }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>{{ $t("dashboard.statistics") }}</h5>
            <el-empty :description="$t('dashboard.nostatistics')" v-if="salesdata.length === 0"></el-empty>
            <el-table v-else :data="salesdata" style="width: 100%">
              <el-table-column prop="date" :label="$t('dashboard.date')" width="180">
              </el-table-column>
              <el-table-column prop="sum" :label="$t('dashboard.ordersum')" width="180">
              </el-table-column>
              <el-table-column :label="$t('dashboard.sales')">
                <template slot-scope="scope">
                  <span>{{ scope.row.sale }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('dashboard.salestotal')">
                <template slot-scope="scope">
                  <span>{{ scope.row.total }} ML</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('home.password.default')" :visible.sync="dialogVisible">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item :label="$t('home.password.newpassword')" prop="newPassword">
          <el-input v-model="form.newPassword" :placeholder="$t('home.password.newpassword_tip')"
            show-password></el-input>
        </el-form-item>
        <el-form-item :label="$t('home.password.newpassword_check')" prop="confirmPassword">
          <el-input v-model="form.confirmPassword" :placeholder="$t('home.password.newpassword_check_tip')"
            show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t("global.button.cancel") }}</el-button>
        <el-button type="primary" @click="submitForm">{{ $t("home.password.submit") }}</el-button>
      </div>
    </el-dialog>
  </Layout>
</template>
<style>
.topmodel {
  height: 400px;
  position: relative;
  overflow: hidden;
}

.showbox {
  height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.showbox::-webkit-scrollbar {
  width: 4px;
}

.showbox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
}

.showbox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
</style>
<style scoped>
.dialog-footer {
  text-align: right;
}
</style>